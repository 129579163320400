<template>
  <b-form @submit="fm_submit">
    <label class="vgs-ac-label" for="fm_email">{{ labels.EMailAddress }}</label>
    <b-form-input
      type="text"
      id="fm_email"
      name="email"
      maxlength="50"
      v-model="form.email"
      @blur="$v.form.email.$touch()"
      :placeholder="this.labels.EMailAddress"
      :aria-label="this.labels.EMailAddress"
      :class="{ 'is-invalid': $v.form.email.$error }"
      class="mb-3 rounded vgs-form-input"
    ></b-form-input>
    <div v-if="$v.form.email.$error" class="invalid-feedback error-class">
      <span v-if="!$v.form.email.required" class="float-left mb-2 mt-n2">{{
        labels.EmailRequired
      }}</span>
      <span v-if="!$v.form.email.email" class="float-left mb-2 mt-n2">{{
        labels.InvalidEmail
      }}</span>
    </div>

    <label class="vgs-ac-label" for="fm_password">{{
      labels.EMailAddress
    }}</label>

    <b-form-input
      type="password"
      name="password"
      maxlength="50"
      id="fm_password"
      v-model="form.password"
      @blur="$v.form.password.$touch()"
      :placeholder="this.labels.Password"
      :aria-label="this.labels.Password"
      class="mb-3 rounded vgs-form-input"
      :class="{ 'is-invalid': $v.form.password.$error }"
    ></b-form-input>
    <div v-if="$v.form.password.$error" class="invalid-feedback error-class">
      <span v-if="!$v.form.password.required" class="float-left mb-2 mt-n2">{{
        labels.PasswordRequired
      }}</span>
    </div>
    <div class="vgs-error-messages" v-html="get_error_messages"></div>
    <b-button
      type="submit"
      variant="primary"
      :disabled="form.submitDisabled"
      class="w-100 p-3 rounded vgs-form-button"
      >{{ labels.SignIn }}</b-button
    >
  </b-form>
</template>

<script>
import axios from "axios";
import { vcm } from "@/mixins/vcm";
import { customErrors } from "@/mixins/customErrors";
import { required, email } from "vuelidate/lib/validators";
import { customLabels } from "@/mixins/customLabels";
import Vue from 'vue';
//const passwordValidator = helpers.regex('alphaNumAndDot', '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$');
export default {
  mixins: [vcm, customErrors, customLabels],
  name: "SignInForm",
  props: {
    queryType: {
      type: String,
    },
    // uuid:{
    //   type:String,
    // }
  },
  data() {
    return {
      isSuccess: false,
      labels: {
        EMailAddress: this.get_scope_translation("EMailAddress", "label"),
        Password: this.get_scope_translation("Password", "label"),
        SignIn: this.get_scope_translation("SignIn", "label"),
        EmailRequired: this.get_scope_translation("EmailRequired", "label"),
        PasswordRequired: this.get_scope_translation(
          "PasswordRequired",
          "label"
        ),
        InvalidEmail: this.get_scope_translation("InvalidEmail", "label"),
      },
      form: {
        email: "",
        password: "",
        submitDisabled: false,
      },
      submitted: false,
      credentials: {},
    };
  },

  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  computed: {},
  created: function () {},
  mounted() {
    let queryType = this.queryType;
    if (queryType && queryType === "success") {
      this.isSuccess = true;
      this.add_error_message(
        this.signIn.EmailVerificationSuccess,
        this.isSuccess
      );
    } else if (queryType === "failed") {
      this.add_error_message(
        this.signIn.EmailVerificationFailed,
        this.isSuccess
      );
    } else if (queryType === "active") {
      this.isSuccess = true;
      this.add_error_message(
        this.signIn.AlternateEmailVerificationSuccess,
        this.isSuccess
      );
    } else if (queryType === "invalid") {
      this.add_error_message(
        this.signIn.AlternateEmailVerificationFailed,
        this.isSuccess
      );
    }
      else if (queryType === "expired") {
      this.add_error_message(
        this.errors.DeleteCustomerLink,
        this.isSuccess
      );
    }

    // for signin page error
    var fieldLabelData = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "SignInForm"
    );
    if (fieldLabelData === "undefined") {
      const Url =
        process.env.VUE_APP_BASE_URL +
        `/v1/labels/${this.$root.config.localization.language}`;
      axios.get(Url).then(function (response) {
        console.log("response" + response);
        if (response.data.responseCode === "200" && response.data.response) {
          for (var j = 0; j < response.data.response.length; j++) {
            localStorage.setItem(
              "form_field_labels_" +
                this.$root.config.localization.language +
                "_" +
                response.data.response[j].Namespace,
              JSON.stringify(response.data.response[j])
            );
          }
          fieldLabelData = localStorage.getItem(
            "form_field_labels_" +
              this.$root.config.localization.language +
              "_SignInForm"
          );
          const FiledLabels = JSON.parse(fieldLabelData);
          for (var i = 0; i < FiledLabels.Label.length; i++) {
            if (FiledLabels.Label[i].Name == "EMailAddress") {
              this.labels.EMailAddress = FiledLabels.Label[i].Label;
            }
            if (FiledLabels.Label[i].Name == "Password") {
              this.labels.Password = FiledLabels.Label[i].Label;
            }
            if (FiledLabels.Label[i].Name == "SignIn") {
              this.labels.SignIn = FiledLabels.Label[i].Label;
            }
            if (FiledLabels.Label[i].Name == "EmailRequired") {
              this.labels.EmailRequired = FiledLabels.Label[i].Label;
            }
            if (FiledLabels.Label[i].Name == "PasswordRequired") {
              this.labels.PasswordRequired = FiledLabels.Label[i].Label;
            }
            if (FiledLabels.Label[i].Name == "InvalidEmail") {
              this.labels.InvalidEmail = FiledLabels.Label[i].Label;
            }
          }
        }
      });
    } else {
      Object.assign(this.labels, fieldLabelData);
    }
  },

  methods: {
    fm_submit(evt) {
      // Call //
      this.submitted = true;
      var fieldsValidated = true;

      this.form.submitDisabled = true;

      // TEMP > PROTECED DEV ONLY //
      // if (this.form.email == "") {
      //   this.form.email = "sabrina.mayr@villach.at";
      // }
      // if (this.form.password == "") {
      //   this.form.password = "abcd1234!PW";
      // }
      // TEMP > PROTECED DEV ONLY //

      this.$v.$touch();
      if (this.$v.form.$invalid) {
        this.form.submitDisabled = false;
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        this.$root.data_access.setIdentifier("sign.in.form");
        this.$root.data_access.setReceiver("component", this);
        this.credentials = this.$root.get_user_credentials(
          "User",
          "Confirmed",
          this.form.email,
          this.form.password
        );

        this.$root.data_access.setRequestBody(this.credentials);
        this.$root.data_access.call("signin");
      }

      evt.preventDefault();
    },
    receive(dataObject) {
      if (dataObject) {
        switch (dataObject.identifier) {
          case "sign.in.form":
            if (dataObject.data) {
              var errorUnauthorized = true;
              if (dataObject.data.response) {
                var userIntitialized = this.$root.set_system_user(
                  "sign.in",
                  dataObject.data.response,
                  this.credentials
                );
                if (userIntitialized) {
                  this.form.submitDisabled = false;
                  errorUnauthorized = false;
                 let userUUID = Vue.vclGet('user_uuid', '');
                  if(this.queryType == 'valid' && userUUID === this.$route.query.uuid){
                     localStorage.setItem("isDeletePageAccessible", true);
                    this.$router.push({ name: "deleteuser" });
                  }
                  else {
                  localStorage.removeItem("isDeletePageAccessible");
                  this.$root.goto("startpage");
                }
                }
              }

              if (errorUnauthorized) {
                this.form.submitDisabled = false;
                this.add_error_message(this.errors.SignInFailed);
              }
            }
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.error-class {
  font-size: 17px;
}
.error-class > span {
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.vgs-form-indent form .form-control.is-invalid {
  background-image: none !important;
}
</style>
