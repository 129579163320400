<template>
    <Page mode="jacket">
        <h1>{{ labels.YourPersonalCitizenAccount }}</h1>
        <p v-html="labels.Introduction"></p>
        <div class="vgs-form-indent">
            <SignInForm :queryType="$route.query.status"></SignInForm>
        </div>
        <div class="vgs-form-indent text-left">
            <RouteLink route="signup" icon="arrow-right" :before="signIn.NoEGovernmentAccountYet" :text="signIn.RegisterNow"></RouteLink>
            &nbsp;<a class="fake-route-link" :href="HandySignatureForm">{{ signIn.EIDGoToSignIN }}</a>
            <!--<div>
                <p class="eid-warning">Aufgrund von Wartungsarbeiten an Backend&#8209;Systemen (Anmeldung Handy&#8209;Signatur / ID&#8209;Austria) kann es derzeit zu Beeintr&auml;chtigungen beim Login kommen.</p>
                <p class="eid-warning">Wir bitten die Unannehmlichkeiten zu entschuldigen.</p>
            </div>-->
            <!-- &nbsp;<a class="fake-route-link" :href="HandySignatureFormMandate">{{ signIn.EIDGoToSignINMandate }}</a> -->
            <RouteLink route="forgotpassword" icon="arrow-right" :text="signIn.ForgotPassword"></RouteLink>
        </div>
    </Page>
</template>

<script>
    import Page from '@/structure/Page.vue'
    import { constants } from '../constants/constant';
    import SignInForm from '@/forms/SignInForm.vue'
    import RouteLink from '@/structure/RouteLink.vue'
    import { vcm } from '@/mixins/vcm'
    import { vvm } from '@/mixins/vvm'
    import { customLabels } from "@/mixins/customLabels";
    import axios from "axios"
    import { mapActions } from 'vuex';
    import Vue from "vue";
    export default {
        mixins: [vcm, vvm, customLabels],
        name: 'SignIn',
        components: {
            Page, SignInForm, RouteLink
        },
        data() {
            return {
                HandySignatureForm: constants.API.SIGNUP_EID,
                HandySignatureFormMandate: constants.API.SIGNUP_EID_MANDATE,
                labels: {
                    YourPersonalCitizenAccount: this.get_scope_translation('YourPersonalCitizenAccount', 'label'),
                    Introduction: this.get_scope_translation('Introduction', 'label'),
                }
            };
        },
        created() {
            this.getInitialize();
        },
        async beforeCreate() {
            try {
                const Url = process.env.VUE_APP_BASE_URL + '/v1/pages/signin'
                const response = await axios.get(Url, { params: { language: `${this.$root.config.localization.language}` } })
                if (response.data.responseCode === '200' && response.data.response) {
                    this.labels.YourPersonalCitizenAccount = response.data.response.Title
                    this.labels.Introduction = response.data.response.Description;
                    Vue.vclSet('application_title', response.data.response.ApplicationTitle);
                }
            } catch (e) {
                console.log('Error : ' + e)
            }
        },

        methods: {
            ...mapActions({
                getInitialize: 'initialize/getInitialize'
            }),
            receive() {

            }
        }
    }

</script>

<style scoped lang="scss">

    h1 {
        font-size: 1.875rem;
        line-height: 2.563rem;
    }

    p {
        font-size: 1rem;
        line-height: 1.625rem;
        color: #000;
    }

    .eid-iframe iframe {
        border: none;
        margin-top: 10px;
        padding: 20px 20px;
        border: 1px solid #f2f2f2;
        width: 100%;
        min-width: 350px;
        min-height: 350px;
    }

    .eid-iframe {
        min-width: 350px;
        min-height: 350px;
    }

    .eid-warning {
        color: #E3000F !important;
        padding-left: 15px;
    }


    // Styles copied from src/structure/RouteLink.vue
    // TODO: remove once link has been fixed

    @import "@/assets/_var.scss";

    .fake-route-link {
        color: $appFontColor;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.875rem;
        border-bottom: 1px solid transparent;
    }

    .fake-route-link span {
        white-space: nowrap;
    }

    .fake-route-link:focus {
        outline: none;
        border-top: none;
        border-left: none;
    }

    .fake-route-link:hover {
        color: $primaryColor;
    }
</style>

