// eslint-disable-next-line
import Vue from 'vue';

export const customErrors = {
  data() {
    return {
      errors: {
        SignInFailed: this.get_translation('Error', 'SignInFailed', 'label'),
        EMailValidationFailed: this.get_translation('Error', 'EMailValidationFailed', 'label'),
        SignUpNPFormUserAlreadyExists: this.get_scope_translation('UserAlreadyExists', 'label'),
        SignUpNPFormBirthDateNotValid: this.get_scope_translation('BirthDateNotValid', 'label'),
        SignUpLPFormUserAlreadyExists: this.get_scope_translation('UserAlreadyExists', 'label'),
        SignUpLPFormBirthDateNotValid: this.get_scope_translation('BirthDateNotValid', 'label'),
        DeleteCustomerLink: this.get_scope_translation('DeleteCustomerLink', 'label')
      },
      success: {
        RegisterSuccess: this.get_scope_translation('RegisterSuccess', 'label'),
      },
    };
  },
  mounted: function() {
    let errorLabel = this.$root.get_label_fields(`${this.$root.config.localization.language}`, 'Error');
    let successLabel = this.$root.get_label_fields(`${this.$root.config.localization.language}`, 'Success');
    if (errorLabel && Object.keys(errorLabel).length > 0) {
      for (let labelKey in errorLabel) {
        this.errors[labelKey] = errorLabel[labelKey];
      }
    }
    if (successLabel && Object.keys(successLabel).length > 0) {
      for (let labelKey in successLabel) {
        this.success[labelKey] = successLabel[labelKey];
      }
    }
  },
  methods: {},
};
